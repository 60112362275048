import React from 'react';
import videojs from 'video.js';
import '@videojs/http-streaming';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import 'video.js/dist/video-js.css';
import '../../styles/player.css';

export const VideoPlayer = (props, live, setLive) => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [noLive, setNoLive] = React.useState(false);
  const { options, onReady, contenuto } = props;

  React.useEffect(() => {
    if (playerRef.current) {
      const videoElement = playerRef.current.el();
      if(live) {
        videoElement.classList.add('vjs-live');
      } else {
        videoElement.classList.remove('vjs-live');
      }
    }
    }, [live]);

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");
      videoElement.setAttribute('disableRemotePlayback', 'true');
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.classList.add('w-full');
      videoElement.classList.add('h-[65vh]');
      videoElement.classList.add('md:h-[75vh]');
      videoElement.classList.add('vjs-theme-stampaleccese');
      videoElement.classList.add('vjs-liveui');
      videoRef.current.appendChild(videoElement);

      var overlaymeta = document.createElement('div');
      overlaymeta.className = 'overlaymeta flex items-center absolute top-0 left-0 w-full z-50 pointer-events-none py-3 px-6 select-none';
      const element = (
        <>
          <div className="flex-grow text-lg lg:text-3xl ml-2.5 leading-none" dangerouslySetInnerHTML={{ __html: "" }}></div>
          <a className="noi fa-regular fa-share-nodes fa-2x" onClick={() => console.log("AABBC")}></a>
        </>
      );
      ReactDOM.createRoot(overlaymeta).render(element);
      options.html5 = {
        vhs: {
          withCredentials: true
        }
      };
      const player = playerRef.current = videojs(videoElement, options, () => {
        player.hlsQualitySelector({
          displayCurrentQuality: true
        });
        onReady && onReady(player);
      });
      player.on('error', () => {
        const error = player.error();
        if (error && error.code === 4 && live) { // codice di errore per sorgente non trovata
          setNoLive(true);
        }
      });
      //player.el().appendChild(overlaymeta);
    } else {
      const player = playerRef.current;
      player.options.html5 = {
        vhs: {
          withCredentials: false
        }
      };
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      {noLive ? (
        <div className="w-full h-[65vh] md:h-[75vh] flex items-center justify-center bg-black text-white text-2xl">
          <p className='hidden'>Al momento non ci sono dirette.</p>
          <img className='w-full h-[65vh] md:h-[75vh] object-contain' src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/SMPTE_Color_Bars_16x9.svg/1920px-SMPTE_Color_Bars_16x9.svg.png"></img>
        </div>
      ) : (
        <div data-vjs-player>
          <div ref={videoRef} />
        </div>
      )}
    </>
  );
}

export default VideoPlayer;