import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Button} from "@nextui-org/react";

const Header = () => {
	return (
		<>
        <Navbar maxWidth="full">
            <NavbarBrand>
                <p className="font-bold text-inherit">Stampa Leccese</p>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarItem isActive>
                <Link href="#" aria-current="page">
                    Home
                </Link>
                </NavbarItem>
            </NavbarContent>
            </Navbar>
		</>
	);
}

export default Header;