import React, { useState, useEffect } from 'react';
import "@fontsource/nunito";
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import './styles/app.css';
import Header from './components/website/Header';
import Footer from './components/website/Footer';
import Homepage from './pages/Homepage';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

function App() {
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" exact element={<><Homepage /><ScrollToTop /></>} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
