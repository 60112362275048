import React from "react";
import SpazioPlayerHome from "../components/homepage/SpazioPlayerHome";
import CardVideo from "../components/card/CardVideo";

const Homepage = () => {
    const [live, setLive] = React.useState(true);
    const [file, setFile] = React.useState("https://cph-msl.akamaized.net/hls/live/2000341/test/master.m3u8");
    return (
        <div className="container">
            <SpazioPlayerHome live={live} setLive={setLive} file={file} />
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-5">
                <CardVideo titolo="Canale video" immagine="https://vito-leccese.it/cms/contents/static/notizie/496d6d6167696e652d57686174734170702d323032342d30352d31392d6f72652d31362e34382e31305f3234346235663630.jpg" live={true} setLive={setLive} setFile={setFile} file="https://cph-msl.akamaized.net/hls/live/2000341/test/master.m3u8" />
                <CardVideo titolo="Test replica" immagine="https://vito-leccese.it/cms/contents/static/notizie/496d6d6167696e652d57686174734170702d323032342d30352d31392d6f72652d31362e34382e31305f3234346235663630.jpg" live={false} setLive={setLive} setFile={setFile} file="https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_10mb.mp4" />
            </div>
        </div>
    );
}

export default Homepage;