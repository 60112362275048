import React from "react";
import {Card, CardFooter, CardBody, Image, Chip} from "@nextui-org/react";

const CardVideo = ({titolo, immagine, live, setLive, setFile, file}) => {
    return (
        <Card className="py-4" onPress={() => {setLive(live);setFile(file)}} isPressable={true}>
        <CardBody className="overflow-visible py-2">
            <Image
            alt="Card background"
            className="object-cover rounded-xl"
            src={immagine}
            width={"100%"}
            />
        </CardBody>
        <CardFooter className="pb-0 pt-2 px-4 flex-col items-start">
            {live ? (
                <Chip color="danger" size="sm">DIRETTA</Chip>
            ) : (
                <Chip color="default" size="sm">REPLICA</Chip>
            )}
            <h4 className="font-bold text-large">{titolo}</h4>
        </CardFooter>
        </Card>
    )
}

export default CardVideo;