import React from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardBody, Divider, Chip } from "@nextui-org/react";
import VideoPlayer from "../player/Player";

const SpazioPlayerHome = ({live, setLive, file}) => {
    const navigate = useNavigate();
    const playerRef = React.useRef(null);
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: false
    };

    React.useEffect(() => {
        if (playerRef.current) {
            if (file.includes(".mp4")) {
                playerRef.current.src({
                    src: file,
                    type: 'video/mp4'
                });
            } else {
            playerRef.current.src({
                src: file,
                type: 'application/x-mpegURL'
            });
            }
        }
      }, [file]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.src({
            src: file,
            type: 'application/x-mpegURL'
        });

        player.on('dispose', () => {
          //videojs.log('player will dispose');
        });
      };

    return (
        <Card className="w-full">
            <CardHeader className="flex gap-3">
                <div className="flex flex-col">
                    {live ? (
                        <Chip color="danger">DIRETTA</Chip>
                    ) : (
                        <Chip color="default">REPLICA</Chip>
                    )}
                </div>
            </CardHeader>
            <CardBody className="p-0">
                <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} live={live} setLive={setLive} />
            </CardBody>
            </Card>
    );
}

export default SpazioPlayerHome;