import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Button} from "@nextui-org/react";

const Footer = () => {
	return (
		<><div className='h-5'></div></>
	);
}

export default Footer;